window._ = require('lodash'); 
import { TempusDominus } from '@eonasdan/tempus-dominus';
import intlTelInput from 'intl-tel-input';

try {
    window.animate = require('animate.css');
    window.$ = window.jQuery = require('jquery')
    require('bootstrap');
    window.GLightbox = require('glightbox');
    window.PureCounter = require('@srexi/purecounterjs');
    
    window.TempusDominus = TempusDominus;
    window.intlTelInput = intlTelInput;
    window.validator = require("email-validator"); 
    window.Swal = require('sweetalert2')
    window.Choices = require('choices.js')
    require('admin-lte')
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
